import barAvatar from "@/assets/bar.jpg";
import womanAvatar from "@/assets/woman-avatar.jpg";
import { Button, buttonVariants } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { useTranslation } from "react-i18next";
import { LightBulbIcon } from "./Icons";
import { Avatar, AvatarFallback, AvatarImage } from "./ui/avatar";

export const HeroCards = () => {
  const { t } = useTranslation();
  const benefits = ["Manage your team", "Manage your inventory", ""];

  return (
    <div className="hidden lg:flex flex-row flex-wrap gap-8 relative w-[700px] h-[500px]">
      {/* Testimonial */}
      <Card className="absolute w-[340px] -top-[15px] drop-shadow-xl shadow-black/10 dark:shadow-white/10">
        <CardHeader className="flex flex-row items-center gap-4 pb-2">
          <Avatar>
            <AvatarImage
              style={{ objectFit: "cover" }}
              alt=""
              src={barAvatar}
            />
            <AvatarFallback>SH</AvatarFallback>
          </Avatar>

          <div className="flex flex-col">
            <CardTitle className="text-lg">José García</CardTitle>
            <CardDescription>{t("hero-cards-1-description")}</CardDescription>
          </div>
        </CardHeader>

        <CardContent>{t("hero-cards-1-content")}</CardContent>
      </Card>

      {/* Team */}
      <Card className="absolute right-[20px] top-4 w-80 flex flex-col justify-center items-center drop-shadow-xl shadow-black/10 dark:shadow-white/10">
        <CardHeader className="mt-8 flex justify-center items-center pb-2">
          <img
            src={womanAvatar}
            alt="user avatar"
            className="absolute grayscale-[0%] -top-12 rounded-full w-24 h-24 aspect-square object-cover"
          />
          <CardTitle className="text-center">Laura Rodríguez</CardTitle>
          <CardDescription className="font-normal text-primary">
            {t("hero-cards-2-description")}
          </CardDescription>
        </CardHeader>

        <CardContent className="text-center pb-2">
          <p>{t("hero-cards-2-content")}</p>
        </CardContent>

        <CardFooter>
          {/* <div>
            <a
              rel="noreferrer noopener"
              href="https://github.com/leoMirandaa"
              target="_blank"
              className={buttonVariants({
                variant: "ghost",
                size: "sm",
              })}
            >
              <span className="sr-only">Github icon</span>
              <GitHubLogoIcon className="w-5 h-5" />
            </a>
            <a
              rel="noreferrer noopener"
              href="https://twitter.com/leo_mirand4"
              target="_blank"
              className={buttonVariants({
                variant: "ghost",
                size: "sm",
              })}
            >
              <span className="sr-only">X icon</span>
              <svg
                role="img"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                className="fill-foreground w-5 h-5"
              >
                <title>X</title>
                <path d="M18.901 1.153h3.68l-8.04 9.19L24 22.846h-7.406l-5.8-7.584-6.638 7.584H.474l8.6-9.83L0 1.154h7.594l5.243 6.932ZM17.61 20.644h2.039L6.486 3.24H4.298Z" />
              </svg>
            </a>

            <a
              rel="noreferrer noopener"
              href="https://www.linkedin.com/in/leopoldo-miranda/"
              target="_blank"
              className={buttonVariants({
                variant: "ghost",
                size: "sm",
              })}
            >
              <span className="sr-only">Linkedin icon</span>
              <Linkedin size="20" />
            </a>
          </div> */}
        </CardFooter>
      </Card>

      {/* Pricing */}
      <Card className="absolute top-[200px] left-[50px] w-72  drop-shadow-xl shadow-black/10 dark:shadow-white/10">
        <CardHeader>
          <CardTitle className="flex item-center justify-between">
            {t("hero-cards-3-header")}
            {/* <Badge variant="secondary" className="text-sm text-primary">
              Most popular
            </Badge> */}
          </CardTitle>
          {/* <div>
            <span className="text-3xl font-bold">$0</span>
            <span className="text-muted-foreground"> /month</span>
          </div> */}

          <CardDescription>{t("hero-cards-3-content")}</CardDescription>
        </CardHeader>

        <CardContent>
          <a
            rel="noreferrer noopener"
            href={`${window.location.origin}/backoffice/user-acquisition/signup`}
            target="_blank"
            className={buttonVariants({
              variant: "default",
            })}
          >
            {t("hero-cards-3-action")}
          </a>
        </CardContent>

        {/* <hr className="w-4/5 m-auto mb-4" />

        <CardFooter className="flex">
          <div className="space-y-4">
            {benefits.map((benefit: string) => (
              <span key={benefit} className="flex">
                <Check className="text-green-500" />{" "}
                <h3 className="ml-2">{benefit}</h3>
              </span>
            ))}
          </div>
        </CardFooter> */}
      </Card>

      {/* Service */}
      <Card className="absolute w-[350px] -right-[10px] bottom-[35px]  drop-shadow-xl shadow-black/10 dark:shadow-white/10">
        <CardHeader className="space-y-1 flex md:flex-row justify-start items-start gap-4">
          <div className="mt-1 bg-primary/20 p-1 rounded-2xl">
            <LightBulbIcon />
          </div>
          <div>
            <CardTitle>{t("hero-cards-4-header")}</CardTitle>
            <CardDescription className="text-md mt-2">
              {t("hero-cards-4-content")}
            </CardDescription>
          </div>
        </CardHeader>
      </Card>
    </div>
  );
};
