import {
  createBrowserRouter,
  Outlet,
  RouterProvider,
  useParams,
} from "react-router-dom";
import { Hero } from "./components/Hero";
import { HowItWorks } from "./components/HowItWorks";
import { Navbar } from "./components/Navbar";
import { ScrollToTop } from "./components/ScrollToTop";

import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import "./App.css";
import { CookiesPolicy } from "./components/CookiesPolicy";

const FullScreenWrapper = () => {
  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        top: 0,
        left: 0,
        position: "relative",
        overflowY: "auto",
        overflowX: "hidden",
      }}
    >
      <Outlet />
    </div>
  );
};

const LangWrapper = () => {
  const { lang } = useParams();
  const { i18n } = useTranslation();

  useEffect(() => {
    if (lang) {
      i18n.changeLanguage(lang);
    }
  }, [lang]);

  return <Outlet />;
};

const Landing = () => {
  return (
    <div>
      <Navbar />
      <Hero />
      {/* <Sponsors /> */}
      {/* <About /> */}
      {/* <Features /> */}
      <HowItWorks />
      {/* <Services /> */}
      {/* <Cta /> */}
      {/* <Testimonials /> */}
      {/* <Team /> */}
      {/* <Pricing /> */}
      {/* <Newsletter /> */}
      {/* <FAQ /> */}
      {/* <Footer /> */}
      <ScrollToTop />
    </div>
  );
};

function App() {
  const router = createBrowserRouter([
    {
      path: "/:lang/",
      element: <FullScreenWrapper />,
      children: [
        {
          path: "/:lang/",
          element: <LangWrapper />,
          children: [
            { path: "/:lang/", element: <Landing /> },
            { path: "/:lang/politica-de-cookies", element: <CookiesPolicy /> },
          ],
        },
      ],
    },
    {
      path: "/",
      element: <FullScreenWrapper />,
      children: [
        {
          path: "/",
          element: <LangWrapper />,
          children: [
            { path: "/", element: <Landing /> },
            { path: "/politica-de-cookies", element: <CookiesPolicy /> },
          ],
        },
      ],
    },
  ]);

  return <RouterProvider router={router} />;
}

export default App;
