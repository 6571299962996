export const resources = {
  en: {
    translation: {
      // Navbar
      "navbar-get-started": "Get started",
      "navbar-how-it-works": "How it works",
      "navbar-features": "Features",
      "navbar-login-button": "Login",
      "navbar-sign-up-button": "Sign up",

      // HERO
      "hero-header-1": "Your menu for free.",
      "hero-header-2-1": "Get your ",
      "hero-header-2-2": "QR ",
      "hero-header-2-3": "now.",
      "hero-description": "Build your menu for free. Update it on the go.",
      "hero-action": "Try  now",

      // Hero Cards
      "hero-cards-1-description": "Bar owner",
      "hero-cards-1-content": "I don't require of physical menus anymore!",
      "hero-cards-2-description": "Coffee shop owner",
      "hero-cards-2-content":
        "I find very easy to handle my menus in different languages.",
      "hero-cards-3-header": "Free forever",
      "hero-cards-3-content": "Use our service free of charge.",
      "hero-cards-3-action": "Start now!",
      "hero-cards-4-header": "Backoffice",
      "hero-cards-4-content":
        "Keep all your menu settings centralized. Manage menus for multiple resaturants. And more!",

      // Features (how it works)
      "features-title": "Features",
      "features-description":
        "Learn about the powerful tools and capabilities that make us unique.",
      "features-card-1-title": "QR code",
      "features-card-1-description":
        "Get your QR code and keep updated your menu seamlessly.",
      "features-card-2-title": "Backoffice",
      "features-card-2-description":
        "Admin your menu through our backoffice. Your changes will be seen by your clients in real time.",
      "features-card-3-title": "Multilanguage",
      "features-card-3-description":
        "You can setup the  menu with all the languages that you need.",

      // LanguageSelector
      "language-selector-title": "Select your language",
    },
  },
  es: {
    translation: {
      // Navbar
      "navbar-get-started": "Comenzar",
      "navbar-how-it-works": "Cómo funciona",
      "navbar-features": "Características",
      "navbar-login-button": "Acceso",
      "navbar-sign-up-button": "Inscribirse",

      // Hero
      "hero-header-1": "Tu menú gratis.",
      "hero-header-2-1": "Consigue tu ",
      "hero-header-2-2": "QR ",
      "hero-header-2-3": "ahora.",
      "hero-description":
        "Personaliza tu menú gratis. Actualizalo sobre la marcha.",
      "hero-action": "Empezar",

      // Hero cards
      "hero-cards-1-description": "Dueño de un bar",
      "hero-cards-1-content": "¡Ya no necesito menús físicos!",
      "hero-cards-2-description": "Dueño de cafetería",
      "hero-cards-2-content":
        "Me resulta muy fácil manejar mis menús en diferentes idiomas.",
      "hero-cards-3-header": "Gratis para siempre",
      "hero-cards-3-content": "Utilice nuestro servicio de forma gratuita.",
      "hero-cards-3-action": "¡Empiece ahora!",
      "hero-cards-4-header": "Panel de administración",
      "hero-cards-4-content":
        "Mantenga todas las configuraciones de su menú centralizadas. ",

      // Features
      "features-title": "Características",
      "features-description":
        "Conozca las carecterísticas que nos hacen únicos.",
      "features-card-1-title": "Código QR",
      "features-card-1-description":
        "Obtenga su código QR y mantenga actualizado su menú fácilmente.",
      "features-card-2-title": "Panel de administración",
      "features-card-2-description":
        "Administre su menú a través de nuestra oficina online. ",
      "features-card-3-title": "Multilenguaje",
      "features-card-3-description":
        "Puedes configurar el menú con todos los idiomas que necesites.",

      // Language selector
      "language-selector-title": "Selecciona tu idioma",
    },
  },
};
