import { useTranslation } from "react-i18next";
import { HeaderText, SubheaderText } from "./ui/texts";

export const CookiesPolicy = () => {
  const { i18n } = useTranslation();

  if (i18n.language === "en") {
    return (
      <div>
        <HeaderText style={{ display: "block", marginTop: 20 }}>
          Cookie Policy
        </HeaderText>
        This portal, www.restdali.com, like most portals on the Internet, uses
        cookies and similar technologies, hereinafter referred to as "Cookies"
        for ease of use. Below, you will find information about what cookies
        are, what type of cookies this portal uses, the processing of data
        through them, who uses these cookies and how you can configure their
        use. If you cannot find the specific information you are looking for,
        please contact contacto@restdali.com
        <SubheaderText style={{ display: "block", marginTop: 20 }}>
          What are cookies?
        </SubheaderText>
        Cookies are small files that some platforms, such as web pages, can
        install in the browser of your computer, smartphone, tablet or connected
        television, which store and retrieve information when you browse. Their
        purposes can be very varied, such as, for example, recognizing you as a
        user, storing your browsing preferences, collecting statistical
        information, personalizing the content or advertising shown to you based
        on your profile, allowing certain technical functionalities, etc.
        Sometimes, cookies are used to store basic information about the user's
        browsing habits, to the point, depending on the case, of being able to
        recognize him.
        <HeaderText style={{ display: "block", marginTop: 20 }}>
          What type of cookies do we use and for what purpose?
        </HeaderText>
        <SubheaderText style={{ display: "block", marginTop: 20 }}>
          Performance improvement cookies
        </SubheaderText>
        This type of cookies saves your preferences for certain tools or
        services, allowing us to remember these preferences each time you visit
        our portal. The preferences we are referring to are those related to the
        volume level of the audiovisual players, preferences for sorting items
        or compatible video playback speeds. In the case of e-commerce, they
        allow us to keep information about your shopping basket. Our website
        uses third-party services that may install cookies to improve
        performance. These cookies are persistent, so the processing of the
        information obtained through them does not end with each session.{" "}
        <SubheaderText style={{ display: "block", marginTop: 20 }}>
          Statistical analysis cookies
        </SubheaderText>
        These are cookies that, whether processed by us or by third parties,
        allow us to quantify the number of visitors and statistically analyse
        the use that users make of our services. Thanks to this, we can study
        the navigation on our website, and thus adjust the offer of products or
        services that we offer. This analysis is carried out using our own tools
        or those of third parties provided by third parties such as Google
        Analytics. The retention period is 38 months.
        <SubheaderText style={{ display: "block", marginTop: 20 }}>
          Geolocation cookies
        </SubheaderText>
        If you consent to their installation, these cookies will be used to
        locate the geographic position of the computer, smartphone, tablet or
        connected television that you use, in order to offer you content and
        services according to your location. These cookies, which may be our own
        or third-party, are session cookies, so that the information obtained
        through them is only processed while you access our page, and is not
        subsequently stored.
        <SubheaderText style={{ display: "block", marginTop: 20 }}>
          Registration cookies
        </SubheaderText>
        When you register on our portal, cookies are generated that identify you
        as a registered user and indicate when you have identified yourself on
        the portal through any device you use for this purpose. These cookies
        are used to identify your user account and its associated services.
        These cookies and the information obtained through them are maintained
        as long as you do not leave the account, close the browser or turn off
        the device. If you give your consent to this, the information obtained
        from these cookies may be used in combination with analytical data to
        individually identify your preferences on our portal. Likewise, if you
        give your consent to this, we can enrich your advertising profile with
        the data you provide us when you register. To do this we contract with
        companies that provide us with their services and that install cookies
        on our behalf. Our own content recommendation cookies These are those
        that allow us to offer you content adapted to your preferences based on
        your profile when you browse our portal. If you consent to their
        installation, we use our own cookies to know your content and browsing
        preferences to create profiles. If you consent, when you browse the web
        portal www.restdali.com we install cookies or use other identifiers that
        allow us to know your browsing habits on all of them, with the purpose
        of creating profiles that, in our case, we then offer to our advertisers
        so that they can serve you advertising or editorial content adapted to
        your preferences, even in other contexts, such as other portals or
        applications of Grupo Godó or third parties. We also create and use
        profiles to personalize your browsing or offer you personalized content
        based on said profile. To do this, we contract with companies that
        provide us with their services and that install cookies on our behalf.
        The retention period is 12 months.
        <SubheaderText style={{ display: "block", marginTop: 20 }}>
          Third-party content recommendation cookies
        </SubheaderText>
        These are used by other companies with which we have agreements in order
        to offer you content according to your preferences and browsing profile
        when you browse our portal or even other portals. Only if you indicate
        so, these companies will install their cookies and offer you content
        based on your preferences and profile. These companies have their own
        privacy policies, which you can consult through the “cookie settings”
        link in the “see our partners” button.{" "}
        <SubheaderText style={{ display: "block", marginTop: 20 }}>
          Own advertising cookies
        </SubheaderText>
        These are those that allow us to effectively manage the advertising
        spaces on our website, adapting the content of the advertisement to your
        preferences and profile. Thanks to them, we can find out about your
        browsing habits on our portal and show you advertising related to your
        browsing profile. If you consent to their installation, we can install
        our own advertising cookies to find out about your browsing preferences
        and create advertising profiles that we can offer to our advertisers. If
        you consent, when you browse the web portal www.restdali.com we install
        cookies or use other identifiers that allow us to know your browsing
        habits for all of them, in order to create profiles that, in our case,
        we then offer to our advertisers so that they can serve you advertising
        or editorial content adapted to your preferences, even in other
        contexts, such as other portals or applications of Grupo Godó or third
        parties. We also create and use profiles to personalize your browsing or
        offer you personalized content based on said profile. To do this, we
        contract with companies that provide us with their advertising services
        and that install cookies on our behalf. The retention period is 12
        months.
        <SubheaderText style={{ display: "block", marginTop: 20 }}>
          Third-party advertising cookies
        </SubheaderText>
        These are used by other companies to effectively manage their
        advertising spaces on our website, adapting the content of the
        advertisement to your preferences and profile. Thanks to them, these
        companies can find out about your browsing habits on our portal and
        other portals on the Internet and show you advertising related to your
        browsing profile. Only if you consent to this, these companies will
        install their cookies and offer you content based on your preferences
        and browsing profile. The companies that serve personalized advertising
        have their own privacy policies, which you can consult through the
        “cookie settings” link in the “see our partners” button.
        <SubheaderText style={{ display: "block", marginTop: 20 }}>
          Other third-party cookies
        </SubheaderText>
        If you consent, third-party cookies will be installed to manage and
        improve the services they offer. An example of this use are the links to
        social networks that allow sharing our content. These companies have
        their own privacy policies, so we recommend that you consult them.
        Likewise, to offer you videos on our portal, we use services from
        third-party video platforms, in particular YouTube, including its
        unbranded version. We also use YouTube's API services in order to record
        activity on these video platforms and thus show you content and
        advertising related to your preferences and usage and browsing profile,
        all in accordance with the terms of these services, which you can
        consult at https://www.youtube.com/t/terms. Likewise, when you play one
        of the aforementioned videos, YouTube uses information about your
        preferences to offer you related videos. In addition, we inform you that
        YouTube may process your personal data in other ways for other purposes.
        You can find more information about YouTube's privacy policies at
        http://www.google.com/policies/privacy.
        <HeaderText style={{ display: "block", marginTop: 20 }}>
          How can you configure your preferences?
        </HeaderText>
        We offer you several options. You can configure your preferences
        regarding our portal and third parties that install cookies by accessing
        "Cookie settings", access to which can be found at the bottom of our
        page and here. You can also allow, block or delete cookies installed on
        your computer by configuring the options of your Internet browser. If
        you block them, certain services that require their use may not be
        available to you. Below we provide links where you will find information
        on how to activate your preferences in the main browsers: Google Chrome
        Mozilla Firefox Internet Explorer Safari Safari for iOS (iPhone, iPad)
        Flash Cookies
        <SubheaderText style={{ display: "block", marginTop: 20 }}>
          Who are the recipients of the data collected through cookies?
        </SubheaderText>
        The information obtained through cookies is known by the owner of the
        same, that is, by us (through our own cookies) or by those third parties
        that install their cookies on our website for their own purposes
        (third-party cookies). You can consult in the link "Cookie settings",
        located at the bottom of our page, which third parties use their cookies
        on our website, the purposes of said cookies, what data they collect
        through them and consult their privacy policies. If you authorize it in
        your Cookies settings, we will share your browsing data, such as your IP
        address, advertising identifiers or your type of device, with our
        advertisers, advertising agencies, our advertising services and our
        analysis platforms, which will use them to show you personalized ads
        based on said information, create or identify advertising profiles and
        measure the performance of the ads. Likewise, and only if you authorize
        it in your Cookie Settings, we will share your advertising profiles with
        third-party companies selected by us, preferably from the advertising or
        content creation and distribution sectors, who may use this profile for
        their own advertising and content promotion campaigns.
        <SubheaderText style={{ display: "block", marginTop: 20 }}>
          What international transfers are made?
        </SubheaderText>
        We inform you that we work with technology providers located outside the
        European Economic Area, so we transfer your data to third countries
        outside said area, however we only do so to countries that have been
        declared to have an adequate level of protection by the European
        Commission or, where appropriate, under guarantee mechanisms recognized
        by the EU such as the subscription of Standard Data Protection Clauses
        adopted by the European Commission or to American entities certified in
        the new Privacy Framework and included in the EU-U.S. Data Privacy
        Framework List, under the Adequacy Decision of the European Commission.
        Regarding international transfers made by third parties that install
        cookies on our website for their own purposes, you should consult their
        own privacy policies through the “see our partners” button located at
        the bottom of our Configuration Panel that you will find at the bottom
        of our page.
        <SubheaderText style={{ display: "block", marginTop: 20 }}>
          How can you exercise your data protection rights?
        </SubheaderText>
        You can exercise your rights of access, rectification, deletion,
        opposition, limitation and portability by directing your request to
        contacto@restdali.com. Likewise, we remind you that you can, at any
        time, revoke the consent previously given for the use of cookies through
        the “cookie settings” link located at the bottom of our page. Finally,
        we inform you that for any additional information regarding the
        processing of your personal data, you can contact our Data Protection
        Officer by sending an email to contacto@restdali.com. Likewise, if you
        consider that your rights are not adequately addressed, we remind you of
        your right to file a claim with the Spanish Data Protection Agency
        (www.aepd.es). For more information on the processing of your personal
        data, you can consult our privacy policy.
      </div>
    );
  }

  return (
    <div>
      <HeaderText style={{ display: "block", marginTop: 20 }}>
        Política de cookies
      </HeaderText>
      Este portal, al igual que la mayoría de portales en Internet, usa cookies
      y tecnologías similares, en adelante denominadas todas ellas,
      indistintamente ”Cookies” para mayor facilidad . A continuación,
      encontrará información sobre qué son las cookies, qué tipo de cookies
      utiliza este portal, el tratamiento de los datos realizados a través de
      aquéllas, quien utiliza estas cookies y sobre cómo puede configurar el uso
      de las mismas. Si no encuentra la información específica que usted está
      buscando, por favor diríjase a contacto@restdali.com
      <SubheaderText style={{ display: "block", marginTop: 20 }}>
        ¿Qué son las cookies?
      </SubheaderText>
      Las cookies son pequeños archivos que algunas plataformas, como las
      páginas web, pueden instalar en el navegador de su ordenador, smartphone,
      tableta o televisión conectada, que almacenan y recuperan información
      cuando navega. Sus finalidades pueden ser muy variadas, como, por ejemplo,
      reconocerle como usuario, almacenar sus preferencias de navegación,
      recopilar información estadística, personalizar el contenido o la
      publicidad que se le muestra en base a su perfil, permitir ciertas
      funcionalidades técnicas, etc... En ocasiones, las cookies se utilizan
      para almacenar información básica sobre los hábitos de navegación del
      usuario, hasta el punto, según los casos, de poder reconocerlo.
      <HeaderText style={{ display: "block", marginTop: 20 }}>
        ¿Qué tipo de cookies utilizamos y con qué finalidad?
      </HeaderText>
      <SubheaderText style={{ display: "block", marginTop: 20 }}>
        Cookies de mejora del rendimiento
      </SubheaderText>
      Este tipo de cookies conserva sus preferencias para ciertas herramientas o
      servicios, lo que nos permite recordar dichas preferencias cada vez que
      visita nuestro portal. Las preferencias a las que nos referimos son las
      relacionadas con el nivel del volumen de los reproductores audiovisuales,
      preferencias de ordenación de artículos o velocidades de reproducción de
      vídeo compatibles. En el caso de comercio electrónico, permiten mantener
      información sobre su cesta de la compra. Nuestra web utiliza servicios de
      terceros que podrán instalar cookies para la mejora del rendimiento. Estas
      cookies son persistentes, por lo que el tratamiento de la información
      obtenida a través de ellas no finaliza con cada sesión.
      <SubheaderText style={{ display: "block", marginTop: 20 }}>
        Cookies de análisis estadístico
      </SubheaderText>
      Son aquellas que, bien tratadas por nosotros o por terceros, permiten
      cuantificar el número de visitantes y analizar estadísticamente la
      utilización que hacen los usuarios de nuestros servicios. Gracias a ello
      podemos estudiar la navegación por nuestra página web, y ajustar así la
      oferta de productos o servicios que ofrecemos. Este análisis se realiza
      mediante herramientas propias o de terceros que proporcionan terceros como
      Google Analytics. El plazo de conservación es de 38 meses.
      <SubheaderText style={{ display: "block", marginTop: 20 }}>
        Cookies de geolocalización
      </SubheaderText>
      Si consiente su instalación, estas cookies serán usadas para localizar la
      posición geográfica del ordenador, smartphone, tableta o televisión
      conectada que usted utilice, con el fin de ofrecerle contenidos y
      servicios según su ubicación. Estas cookies que pueden ser propias o de
      terceros, son de sesión, de manera que la información obtenida a través de
      las mismas sólo se trata mientras accede a nuestra página, no
      conservándose posteriormente.
      <SubheaderText style={{ display: "block", marginTop: 20 }}>
        Cookies de registro
      </SubheaderText>
      Cuando usted se registra en nuestro portal, se generan cookies que le
      identifican como usuario registrado e indican cuándo usted se ha
      identificado en el portal a través de cualquier dispositivo que utilice
      para ello. Estas cookies son utilizadas para identificar su cuenta de
      usuario y sus servicios asociados. Estas cookies y la información obtenida
      a través de ellas se mantienen mientras usted no abandone la cuenta,
      cierre el navegador o apague el dispositivo. Si presta su consentimiento
      para ello, la información obtenida de dichas cookies puede ser utilizadas
      en combinación con datos analíticos para identificar de manera individual
      sus preferencias en nuestro portal. Igualmente, si presta su
      consentimiento para ello, podremos enriquecer tu perfil publicitario con
      los datos que nos facilites en tu registro. Para ello contratamos con
      empresas que nos aportan sus servicios y que instalan cookies por encargo
      nuestro. Cookies propias de recomendación de contenidos Son aquéllas que
      nos permiten ofrecerle contenidos adaptados a sus preferencias en base a
      su perfil cuando navega por nuestro portal. Si consiente en su
      instalación, nosotros usamos nuestras propias cookies para conocer sus
      preferencias de contenidos y navegación para crear perfiles. Si lo
      consiente, cuando navega por la web www.restdali.com instalamos cookies o
      usamos otros identificadores que nos permiten conocer sus hábitos de
      navegación por todos ellos, con la finalidad de crear perfiles que, en
      nuestro caso, luego ofrecemos a nuestros anunciantes para que le sirvan
      contenidos publicitarios o editoriales adaptados a sus preferencias,
      incluso en otros contextos, como otros portales o aplicaciones de Grupo
      Godó o de terceros. También creamos y usamos perfiles para personalizar su
      navegación u ofrecerle contenidos personalizados en base a dicho perfil.
      Para ello contratamos con empresas que nos aportan sus servicios y que
      instalan cookies por encargo nuestro. El plazo de conservación es de 12
      meses.
      <SubheaderText style={{ display: "block", marginTop: 20 }}>
        Cookies de terceros de recomendación de contenidos
      </SubheaderText>
      Son aquéllas que utilizan otras empresas con las que tenemos acuerdos con
      el fin de ofrecerle contenidos según sus preferencias y perfil de
      navegación cuando navega por nuestro portal o, incluso, por otros
      portales. Sólo en el caso que usted así lo indique, estas empresas
      instalarán sus cookies y le ofrecerán contenidos basados en sus
      preferencias y perfil. Estas empresas tienen sus propias políticas de
      privacidad, que Vd. puede consultar a través del enlace “configuración de
      cookies” en el botón “ver nuestros socios”.
      <SubheaderText style={{ display: "block", marginTop: 20 }}>
        Cookies publicitarias propias
      </SubheaderText>
      Son aquéllas que nos permiten gestionar eficazmente los espacios
      publicitarios de nuestro sitio web, adecuando el contenido del anuncio a
      sus preferencias y perfil. Gracias a ellas podemos conocer sus hábitos de
      navegación en nuestro portal y mostrarle publicidad relacionada con su
      perfil de navegación. Si consiente su instalación, podemos instalar
      nuestras propias cookies publicitarias para conocer sus preferencias de
      navegación y crear perfiles publicitarios que podemos ofrecer a nuestros
      anunciantes. Si lo consiente, cuando navega por el portales web
      www.restdali.com instalamos cookies o usamos otros identificadores que nos
      permiten conocer sus hábitos de navegación por todos ellos, con la
      finalidad de crear perfiles que, en nuestro caso, luego ofrecemos a
      nuestros anunciantes para que le sirvan contenidos publicitarios o
      editoriales adaptados a sus preferencias, incluso en otros contextos, como
      otros portales o aplicaciones de Grupo Godó o de terceros. También creamos
      y usamos perfiles para personalizar su navegación u ofrecerle contenidos
      personalizados en base a dicho perfil. Para ello contratamos con empresas
      que nos aportan sus servicios publicitarios y que instalan cookies por
      encargo nuestro. El plazo de conservación es de 12 meses.
      <SubheaderText style={{ display: "block", marginTop: 20 }}>
        Cookies publicitarias de terceros
      </SubheaderText>
      Son aquéllas que utilizan otras empresas para gestionar eficazmente sus
      espacios publicitarios en nuestro sitio web, adecuando el contenido del
      anuncio a sus preferencias y perfil. Gracias a ellas estas empresas pueden
      conocer sus hábitos de navegación en nuestro portal y otros portales en
      Internet y mostrarle publicidad relacionada con su perfil de navegación.
      Sólo en el caso que usted así lo consienta, estas empresas instalarán sus
      cookies y le ofrecerán contenidos basados en sus preferencias y perfil de
      navegación. Las compañías que sirven publicidad personalizada tienen sus
      propias políticas de privacidad, que Vd. puede consultar a través del
      enlace “configuración de cookies” en el botón “ver nuestros socios”.
      <SubheaderText style={{ display: "block", marginTop: 20 }}>
        Otras cookies de terceros
      </SubheaderText>
      Si Usted lo consiente, se instalarán cookies de terceros que permitan
      gestionar y mejorar los servicios que éstos ofrecen. Un ejemplo de este
      uso son los enlaces a las redes sociales que permiten compartir nuestros
      contenidos. Estas compañías tienen sus propias políticas de privacidad,
      por lo que le recomendamos que las consulte. Igualmente, para ofrecerle
      vídeos en nuestro portal utilizamos servicios de plataformas de vídeo de
      terceros, en particular YouTube, incluso, en su versión sin marca
      (“unbranded versión”). Asimismo, utilizamos los servicios API de Youtube
      con la finalidad de registrar la actividad en dichas plataformas de video
      y mostrarle así contenido y publicidad relacionada con sus preferencias y
      perfil de uso y navegación, todo ello, de conformidad con los términos de
      dichos servicios, que puedes consultar en https://www.youtube.com/t/terms.
      Asimismo, cuando reproduce uno de los citados vídeos, Youtube utiliza
      información sobre sus preferencias para ofrecerle vídeos relacionados.
      Además, le informamos que Youtube puede realizar otros tratamientos de tus
      datos personales con otras finalidades. Encontrará más información sobre
      las políticas de privacidad de Youtube en
      http://www.google.com/policies/privacy.
      <HeaderText style={{ display: "block", marginTop: 20 }}>
        ¿Cómo puede configurar sus preferencias?
      </HeaderText>
      Ponemos a su disposición varias opciones. Usted puede configurar sus
      preferencias respecto a nuestro portal y a los terceros que instalan
      cookies accediendo a ”Configuración de cookies”, cuyo acceso encontrará en
      el pie de nuestra página y aquí. También puede permitir, bloquear o
      eliminar las cookies instaladas en su equipo mediante la configuración de
      las opciones de su navegador de internet. En el caso en que las bloquee,
      es posible que ciertos servicios que necesitan su uso no estén disponibles
      para usted. A continuación le ofrecemos enlaces en los que encontrará
      información sobre cómo puede activar sus preferencias en los principales
      navegadores: Google Chrome Mozilla Firefox Internet Explorer Safari Safari
      para IOS (iPhone, iPad) Cookies Flash
      <SubheaderText style={{ display: "block", marginTop: 20 }}>
        ¿Quiénes son los destinatarios de los datos recabados mediante las
        cookies?
      </SubheaderText>
      La información obtenida a través de las cookies es conocida por el titular
      de las mismas, es decir, por nosotros (mediante cookies propias) o por
      aquellos terceros que instalan sus cookies en nuestra web para sus propios
      fines (cookies de terceros). Puedes consultar en el enlace “Configuración
      de cookies”, ubicado al pie de nuestra página, qué terceros utilizan sus
      cookies en nuestra web, las finalidades de dichas cookies, qué datos
      recaban a través de ellas y consultar sus políticas de privacidad. Si lo
      autoriza en su Configuración de cookies, compartiremos sus datos de
      navegación, como su dirección IP, identificadores publicitarios o su tipo
      de dispositivo, con nuestros anunciantes, agencias de publicidad, nuestros
      servicios de publicidad y nuestras plataformas de analisis, que los usarán
      para mostrarle anuncios personalizados en base a dicha información, crear
      o identificar perfiles publicitarios y medir el rendimiento de los
      anuncios. Asimismo, y sólo si lo autoriza en su Configuración de cookies,
      compartiremos sus perfiles publicitarios con terceras empresas
      seleccionadas por nosotros, preferentemente de los sectores de la
      publicidad o de la creación y distribución de contenidos, que podrán
      utilizar este perfil para sus propias campañas publicitarias y de
      promoción de contenidos.
      <SubheaderText style={{ display: "block", marginTop: 20 }}>
        ¿Qué transferencias internacionales se realizan
      </SubheaderText>
      Le informamos que trabajamos con proveedores tecnológicos situados fuera
      de del Espacio Económico Europeo, por lo que transferimos sus datos a
      terceros países fuera de dicho espacio, sin embargo únicamente lo hacemos
      a países que han sido declarados de nivel de protección adecuado por la
      Comisión Europea o, en su caso, bajo mecanismos de garantías reconocidos
      por la UE como son la suscripción de Cláusulas Tipo de Protección de Datos
      adoptadas por la Comisión Europea o a entidades estadunidenses
      certificadas en el nuevo Marco de Privacidad e incluidas en EU-U.S. Data
      Privacy Framework List, al amparo de la Decisión de Adecuación de la
      Comisión Europea. En cuanto a las transferencias internacionales que
      realizan los terceros que instalan cookies en nuestra web para sus propios
      fines, debe consultar sus propias políticas de privacidad a través del
      botón “ver nuestros socios” ubicado al final de nuestro Panel de
      Configuración que encontrará al pie de nuestra página.
      <SubheaderText style={{ display: "block", marginTop: 20 }}>
        ¿Cómo puede ejercer sus derechos en materia de protección de datos?
      </SubheaderText>
      Usted puede ejercer sus derechos de acceso, rectificación, supresión,
      oposición, limitación y portabilidad dirigiendo su petición a
      contacto@restdali.com. Igualmente, le recordamos que usted puede, en
      cualquier momento, revocar el consentimiento previamente prestado para el
      uso de cookies a través del enlace “configuración de cookies” ubicado al
      pie de nuestra página. Finalmente le informamos que para cualquier
      información adicional respecto al tratamiento de tus datos personales
      puedes contactar con nuestro Delegado de Protección de Datos enviando un
      mensaje de correo electrónico a contacto@restdali.com. Asimismo, si
      consideras que tus derechos no se atienden adecuadamente, te recordamos tu
      derecho a presentar una reclamación ante la Agencia Española de Protección
      de Datos (www.aepd.es). Para más información sobre el tratamiento de tus
      datos personales puede consultar nuestra política de privacidad.
    </div>
  );
};
