import { useWindowSize } from "@/hooks/useWindowSize";
import { getIsPhone } from "@/lib/deviceSize";
import { useState } from "react";
import { createPortal } from "react-dom";
import { useTranslation } from "react-i18next";

const buttonAccept: { [key: string]: string } = {
  es: "Aceptar",
  en: "Accept",
};

const moreInfoText: { [key: string]: string } = {
  es: "Más información",
  en: "More info",
};

const textToDisplay: { [key: string]: React.ReactElement<unknown, ""> } = {
  es: (
    <div>
      Este sitio web utiliza cookies para personalizar el contenido, recordar
      tus preferencias, realizar un seguimiento del tráfico del sitio web y
      mostrarte publicidad dirigida.
      <br></br>
      <br></br>
      <strong>
        Algunas cookies son esenciales para que el sitio web funcione
        correctamente.
      </strong>
      <br></br>
      <br></br>
      <strong>
        Para continuar necesitamos su consentimiento expreso sobre el uso de
        cookies.
      </strong>{" "}
      <br></br>
      <br></br>
      Para más información consulte nuestra{" "}
      <a
        style={{
          color: "rgb(25, 95, 170)",
          textDecoration: "underline",
        }}
        href={`${window.location.origin}/politica-de-cookies`}
      >
        política de cookies
      </a>
      .<br></br>
      <br></br>
      Los tipos de cookies que utilizamos son:
      <ul className="ul">
        <li>Almacenamiento y acceso a la información,</li>
        <li>
          Compartir datos y perfiles para análisis y publicidad personalizada de
          los anunciantes para nuestras campañas publicitarias,
        </li>
        <li>
          Compartir datos y perfiles para análisis y publicidad personalizada de
          los anunciantes y agencias publicitarias en internet,
        </li>
        <li>
          Datos de localización geográfica precisa e identificación mediante
          análisis de dispositivos,
        </li>
        <li>Publicidad y contenido personalizados,</li>
        <li>Medición de publicidad y contenido,</li>
        <li>Investigación de audiencia y desarrollo de servicios</li>
      </ul>
      Puede retirar su consentimiento o consultar más información acerca de sus
      preferencias en nuestra Política de Cookies.
    </div>
  ),
  en: (
    <div>
      This website uses cookies to personalize content, remember your
      preferences, track website traffic, and show you targeted advertising.
      <br></br>
      <br></br>
      <strong>
        Some cookies are essential for the website to function properly.
      </strong>
      <br></br>
      <br></br>
      <strong>
        In order to continue we need your express consent on the use of cookies.{" "}
      </strong>{" "}
      <br></br>
      <br></br>
      For more information, see our{" "}
      <a
        style={{
          color: "rgb(25, 95, 170)",
          textDecoration: "underline",
        }}
        href={`${window.location.origin}/politica-de-cookies`}
      >
        cookie policy
      </a>
      .<br></br>
      <br></br>
      The types of cookies we use are:
      <ul className="ul">
        <li>Storage and access to information,</li>
        <li>
          Sharing data and profiles for analysis and personalized advertising
          from advertisers for our advertising campaigns,
        </li>
        <li>
          Sharing data and profiles for analysis and personalized advertising
          from advertisers and advertising agencies on the Internet,
        </li>
        <li>
          Precise geographic location data and identification through device
          analysis,
        </li>
        <li>Personalized advertising and content,</li>
        <li>Advertising and content measurement,</li>
        <li>Audience research and service development</li>
      </ul>
      You can withdraw your consent or consult more information about your
      preferences in our Cookies Policy.
    </div>
  ),
};

export const CookiesDisclaimer: React.FC<{ callbackAccept?: () => void }> = ({
  callbackAccept,
}) => {
  const { i18n } = useTranslation();

  const { windowWidth } = useWindowSize();

  const isPhone = getIsPhone(windowWidth);

  const [isOpen, setIsOpen] = useState(
    localStorage.getItem("COOKIE_GPDR") === null
  );
  const [moreInfo, setMoreInfo] = useState(false);

  const language = i18n.language.includes("en") ? "en" : "es";

  const onClickAccept = () => {
    localStorage.setItem("COOKIE_GPDR", "true");
    setIsOpen(false);

    callbackAccept && callbackAccept();
  };

  return (
    isOpen &&
    createPortal(
      <div
        style={{
          width: "100%",
          position: "absolute",
          bottom: 0,
          left: 0,
          backgroundColor: "white",
          boxShadow: "0px 1px 28px -2px rgba(0,0,0,0.75)",
          zIndex: 100,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            width: "100%",
            maxWidth: 1280,
            display: "flex",
            flexDirection: isPhone ? "column" : undefined,
            alignItems: "center",
            gap: 20,
            height: !isPhone ? (moreInfo ? undefined : "25vh") : undefined,
          }}
        >
          <div
            style={{
              flexGrow: 1,
              height: "100%",
              display: "flex",
              flexDirection: "column",
              gap: 20,
              alignItems: "center",
            }}
          >
            <div
              style={{
                overflowY: "auto",
                padding: isPhone ? 20 : 40,
                flexGrow: 1,
                height: isPhone ? (moreInfo ? "60vh" : "15vh") : undefined,
              }}
            >
              {textToDisplay[language]}
            </div>
            {!moreInfo && (
              <div
                style={{
                  color: "rgb(25, 95, 170)",
                  cursor: "pointer",
                  paddingBottom: !isPhone ? 20 : undefined,
                }}
                onClick={() => setMoreInfo(true)}
              >
                {moreInfoText[language]}
              </div>
            )}
          </div>
          <div
            style={{
              width: isPhone ? "100%" : undefined,
              padding: isPhone ? 20 : 40,
              paddingTop: 0,
            }}
          >
            <button
              onClick={onClickAccept}
              style={{
                padding: "0.7rem 1.5rem",
                border: `1px solid rgb(144 144 144)`,
                color: `#212529`,
                fontSize: "1.2rem",
                borderRadius: "0.375rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                width: isPhone ? "100%" : undefined,
                outline: "none",
                backgroundColor: "buttonface",
              }}
            >
              {buttonAccept[language]}
            </button>
          </div>
        </div>
      </div>,
      document.body
    )
  );
};
