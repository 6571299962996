import adminIcon from "@/assets/admin-icon.png";
import qrPhoneIcon from "@/assets/qr-phone.png";
import trasnalteIcon from "@/assets/translate-icon.png";

import { Card, CardContent, CardHeader, CardTitle } from "./ui/card";
import { useTranslation } from "react-i18next";

interface FeatureProps {
  icon: JSX.Element;
  title: string;
  description: string;
}

export const HowItWorks = () => {
  const { t } = useTranslation();

  const features: FeatureProps[] = [
    {
      icon: <img src={qrPhoneIcon} />,
      title: t("features-card-1-title"),
      description: t("features-card-1-description"),
    },
    {
      icon: <img src={adminIcon} />,
      title: t("features-card-2-title"),
      description: t("features-card-2-description"),
    },
    {
      icon: <img src={trasnalteIcon} />,
      title: t("features-card-3-title"),
      description: t("features-card-3-description"),
    },
  ];

  return (
    <section id="howItWorks" className="container text-center py-24 sm:py-32">
      <h2 className="text-3xl md:text-4xl font-bold ">{t("features-title")}</h2>
      <p className="md:w-3/4 mx-auto mt-4 mb-8 text-xl text-muted-foreground">
        {t("features-description")}
      </p>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {features.map(({ icon, title, description }: FeatureProps) => (
          <Card key={title} className="bg-muted/50">
            <CardHeader>
              <CardTitle className="grid gap-4 place-items-center">
                {icon}
                {title}
              </CardTitle>
            </CardHeader>
            <CardContent>{description}</CardContent>
          </Card>
        ))}
      </div>
    </section>
  );
};
