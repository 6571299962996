import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.tsx";
import { ThemeProvider } from "@/components/theme-provider.tsx";
import "./index.css";
import "./localization/localization.ts";
import { CookiesDisclaimer } from "./components/CookiesDisclaimer.tsx";

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <ThemeProvider defaultTheme="light">
      <App />
      <CookiesDisclaimer />
    </ThemeProvider>
  </React.StrictMode>
);
