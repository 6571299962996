import { useState, useTransition } from "react";
import {
  NavigationMenu,
  NavigationMenuItem,
  NavigationMenuList,
} from "@/components/ui/navigation-menu";
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "@/components/ui/sheet";

import { GitHubLogoIcon } from "@radix-ui/react-icons";
import { buttonVariants } from "./ui/button";
import { Menu } from "lucide-react";
import { ModeToggle } from "./mode-toggle";
import { LogoIcon } from "./Icons";
import { LanguageSelector } from "./LanguageSelector";
import { useTranslation } from "react-i18next";

interface RouteProps {
  href: string;
  label: string;
}

export const Navbar = () => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const routeList: RouteProps[] = [
    // {
    //   href: "#hero",
    //   label: t("navbar-get-started"),
    // },
    // {
    //   href: "#howItWorks",
    //   label: t("navbar-features"),
    // },
  ];

  return (
    <header className="sticky border-b-[1px] top-0 z-40 w-full bg-white dark:border-b-slate-700 dark:bg-background">
      <NavigationMenu className="mx-auto">
        <NavigationMenuList className="container h-14 px-4 w-screen flex justify-between ">
          <NavigationMenuItem className="font-bold flex">
            <a
              rel="noreferrer noopener"
              href="/"
              className="ml-2 font-bold text-xl flex"
            >
              {/* <LogoIcon /> */}
              Restdali
            </a>
          </NavigationMenuItem>

          {/* mobile */}
          <span className="flex md:hidden">
            <LanguageSelector />

            {/* <ModeToggle /> */}

            <Sheet open={isOpen} onOpenChange={setIsOpen}>
              <SheetTrigger className="px-2">
                <Menu
                  className="flex md:hidden h-5 w-5"
                  onClick={() => setIsOpen(true)}
                >
                  <span className="sr-only">Menu Icon</span>
                </Menu>
              </SheetTrigger>

              <SheetContent side={"left"}>
                <SheetHeader>
                  <SheetTitle className="font-bold text-xl">
                    Restdali
                  </SheetTitle>
                </SheetHeader>
                <nav className="flex flex-col justify-center items-center gap-2 mt-4">
                  {routeList.map(({ href, label }: RouteProps) => (
                    <a
                      rel="noreferrer noopener"
                      key={label}
                      href={href}
                      onClick={() => setIsOpen(false)}
                      className={buttonVariants({ variant: "ghost" })}
                    >
                      {label}
                    </a>
                  ))}
                  <a
                    rel="noreferrer noopener"
                    href={`${window.location.origin}/backoffice/`}
                    target="_blank"
                    className={`w-[110px] border ${buttonVariants({
                      variant: "secondary",
                    })}`}
                  >
                    {t("navbar-login-button")}
                  </a>
                  <a
                    rel="noreferrer noopener"
                    href={`${window.location.origin}/backoffice/user-acquisition/signup`}
                    target="_blank"
                    className={`w-[110px] border ${buttonVariants({
                      variant: "default",
                    })}`}
                  >
                    {t("navbar-sign-up-button")}
                  </a>
                </nav>
              </SheetContent>
            </Sheet>
          </span>

          {/* desktop */}
          <nav className="hidden md:flex gap-2">
            {routeList.map((route: RouteProps, i) => (
              <a
                rel="noreferrer noopener"
                href={route.href}
                key={i}
                className={`text-[17px] ${buttonVariants({
                  variant: "ghost",
                })}`}
              >
                {route.label}
              </a>
            ))}
          </nav>

          <div className="hidden md:flex gap-2">
            <LanguageSelector />

            <a
              rel="noreferrer noopener"
              href={`${window.location.origin}/backoffice/`}
              target="_blank"
              className={`w-[110px] border ${buttonVariants({
                variant: "secondary",
              })}`}
            >
              {t("navbar-login-button")}
            </a>
            <a
              rel="noreferrer noopener"
              href={`${window.location.origin}/backoffice/user-acquisition/signup`}
              target="_blank"
              className={`w-[110px] border ${buttonVariants({
                variant: "default",
              })}`}
            >
              {t("navbar-sign-up-button")}
            </a>

            {/* <ModeToggle /> */}
          </div>
        </NavigationMenuList>
      </NavigationMenu>
    </header>
  );
};
