interface IStyle {
  style?: React.CSSProperties;
}

export const SubheaderText: React.FC<React.PropsWithChildren<IStyle>> = ({
  children,
  style,
}) => {
  return (
    <span
      style={{
        textAlign: "start",
        fontWeight: "bold",
        fontSize: "1rem",
        ...style,
      }}
    >
      {children}
    </span>
  );
};

export const HeaderText: React.FC<React.PropsWithChildren<IStyle>> = ({
  children,
  style,
}) => {
  return (
    <span
      style={{
        fontWeight: "bold",
        fontSize: "1.3rem",
        wordBreak: "break-word",
        ...style,
      }}
    >
      {children}
    </span>
  );
};
